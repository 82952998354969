<template>
  <molecule-card-wrapper class="user">
    <div class="user__header">
      <molecule-user-infos :user="user" class="user__header-infos"></molecule-user-infos>
      <button @click.prevent="openChat()" class="user__header-cta">
        <span class="user__header-cta-text">Chat</span>
        <atom-icon 
          class="user__header-cta-icon"
          icon="chat-3"
        ></atom-icon>
      </button>
    </div>
    <div class="user__buttons">
      <!-- <atom-button @click.prevent="openChat()" variant="primary md" class="user__buttons-item"
        >Envoyer un message</atom-button
      > -->
    </div>
  </molecule-card-wrapper>
</template>

<script setup>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import MoleculeChatWindow from "@/components/molecules/MoleculeChatWindow.vue";
import MoleculeUserInfos from "@/components/molecules/MoleculeUserInfos.vue";
import { computed, ref, toRefs } from "vue";
import {useStore} from "vuex";
import {messagesMutationTypes} from "@/store/types/mutations";
const {commit, state} = useStore();
const activeUser = computed( () => state.messages.activeUser);
const props = defineProps({
  user:{
    type:Object,
    required:true
  }
});
const openChat = () => {
  commit(`messages/${messagesMutationTypes.SET_ACTIVE_USER}`, props.user);
  commit(`messages/${messagesMutationTypes.PUSH_USER}`, props.user);
  commit(`messages/${messagesMutationTypes.SET_OPEN_STATUS}`, true);
}
</script>

<style lang="scss" scoped>
.user {
  @apply bg-transparent lg:bg-white;
  &__header{
    @apply flex items-center justify-between mb-5;
    &-cta{
      @apply flex items-center gap-[12px] lg:hidden;
      &-text{
        @apply text-base font-medium leading-[19px];
      }
      &-icon{

      }
    }
  }
  &__about {
    @apply hidden mb-1
    lg:block;
  }
  &__description {
    @apply text-sm hidden
    lg:block;
  }
  &__buttons {
    @apply mt-7;
    &-item {
      @apply mr-6;
      &:first-child{
        @apply border-none text-white bg-primary w-full
        lg:border-solid lg:border lg:border-primary 
        lg:text-primary lg:bg-white
        lg:w-auto;
      }
      &:last-child{
        @apply hidden lg:inline-block;
      }
    }
  }
}
</style>
